/* @import url('https://fonts.googleapis.com/css?family=Roboto'); */
@font-face {
  font-family: "SFProText-Regular";
  src: local("SFProText-Regular"),
    url(../../mobile/assets/fonts/SF-Pro-Text-Regular.otf) format("truetype");
}
@font-face {
  font-family: "SFProText-Light";
  src: local("SFProText-Light"),
    url(../../mobile/assets/fonts/SF-Pro-Text-Light.otf) format("truetype");
}
@font-face {
  font-family: "SFProText-SemiBold";
  src: local("SFProText-SemiBold"),
    url(../../mobile/assets/fonts/SF-Pro-Text-Semibold.otf) format("truetype");
}
@font-face {
  font-family: "Sarabun-Regular";
  src: local("Sarabun-Regular"),
    url(../../mobile/assets/fonts/Sarabun-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Sarabun-Bold";
  src: local("Sarabun-Bold"),
    url(../../mobile/assets/fonts/Sarabun-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Sarabun-ExtraBold";
  src: local("Sarabun-ExtraBold"),
    url(../../mobile/assets/fonts/Sarabun-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Quicksand-Bold";
  src: local("Quicksand-Bold"),
    url(../../mobile/assets/fonts/Quicksand-Bold.ttf) format("truetype");
}

html,
body,
#root,
#root > div {
  width: 100%;
  height: 100%;
}

body {
  /* overflow: hidden; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  outline: none !important;
}

select {
  padding-top: 16px;
  padding-bottom: 16px;
  border: 0;
}

.camera_input_wine {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
}

.camera_label_wine {
  flex: 1;
  padding: 18px 18px 18px 0px;
  font-family: "SFProText-Light";
  cursor: pointer;
}
